<template>
  <div class="trade-header">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">归集平台名称</span>
          <el-select
            class="right"
            v-model="isvId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="请输入所属平台"
            :remote-method="remoteMethod"
            :loading="inputLoading"
          >
            <el-option
              v-for="item in platList"
              :key="item.isvId"
              :label="item.isvName"
              :value="item.isvId"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">接入方流水号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="cashSweepNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">交易类型</span>
          <el-select
            class="right"
            size="small"
            v-model="type"
            placeholder="请选择"
            @change="typeRes"
          >
            <el-option
              v-for="item in typeList"
              :key="item.state"
              :label="item.stateName"
              :value="item.state"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">状态</span>
          <el-select
            class="right"
            size="small"
            v-model="state"
            placeholder="请选择"
            @change="stateRes"
          >
            <el-option
              v-for="item in stateList"
              :key="item.state"
              :label="item.stateName"
              :value="item.state"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">归集创建时间</span>
          <el-date-picker
            class="right"
            v-model="dateResult"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">归集方发起名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="virtualAccountName"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>

      <el-col :span="6">
        <div class="grid-content">
          <span class="left">归集方发起账号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="virtualAccountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      
      <el-col :span="6">
        <div class="btn-content">
          <el-button type="primary" size="medium" @click="handleQuery"
            >查询</el-button
          >
          <el-button size="medium" @click="handleClear">清空</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { platManageList } from '@/request/api'
export default {
  data() {
    return {
      inputLoading: false,
      cashSweepNo: '',
      type: '',
      isvId: '',
      state: '',
      virtualAccountName: '',
      virtualAccountNo: '',
      dateResult: '',
      platList: [],
      stateList: [
        { stateName: '成功', state: '1' },
        { stateName: '失败', state: '2' },
        { stateName: '处理中', state: '3' },
      ],
      typeList: [
        { stateName: '资金归集', state: '1' },
        { stateName: '撤销资金归集', state: '2' },
      ],
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    stateRes(state) {
      this.state = state
    },
    typeRes(state) {
      this.type = state
    },
    handleQuery() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const obj = {
        cashSweepNo: this.cashSweepNo,
        type: this.type,
        isvId: this.isvId,
        state: this.state,
        virtualAccountName: this.virtualAccountName,
        virtualAccountNo: this.virtualAccountNo,
        isvName: this.isvName,
        beginDate: beginDate,
        endDate: endDate,
      }
      this.$emit('cashsweep-event', obj)
    },
    handleClear() {
      this.cashSweepNo = ''
      this.type = ''
      this.isvId = ''
      this.state = ''
      this.virtualAccountName = ''
      this.virtualAccountNo = ''
      this.dateResult = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.trade-header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
