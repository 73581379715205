<template>
  <div class="trade-header">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">所属平台</span>
          <el-select
            class="right"
            v-model="isvId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="请输入所属平台"
            :remote-method="remoteMethod"
            :loading="inputLoading"
          >
            <el-option
              v-for="item in platList"
              :key="item.isvId"
              :label="item.isvName"
              :value="item.isvId"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">接入方流水号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="userWithdrawNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">支付流水号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="withdrawNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">交易创建时间</span>
          <el-date-picker
            class="right"
            v-model="dateResult"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="grid-content">
          <span class="left">交易状态</span>
          <el-select
            class="right"
            size="small"
            v-model="stateName"
            placeholder="请选择"
            @change="stateRes"
          >
            <el-option
              v-for="item in stateList"
              :key="item.state"
              :label="item.stateName"
              :value="item.state"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">提现方名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="bindAccountname"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">提现方账户号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="accountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">渠道</span>
          <el-select
            class="right"
            size="small"
            v-model="channelName"
            placeholder="请选择"
            @change="channelRes"
          >
            <el-option
              v-for="item in channelList"
              :key="item.channel"
              :label="item.channelName"
              :value="item.channel"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">提现卡号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="bindAccountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="btn-content">
          <el-button type="primary" size="medium" @click="handleQuery"
            >查询</el-button
          >
          <el-button size="medium" @click="handleClear">清空</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { platManageList } from '@/request/api'
import { CHANNELLIST } from '@/assets/js/config'
export default {
  data() {
    return {
      userWithdrawNo: '',
      withdrawNo: '',
      bindAccountNo: '',
      accountNo: '',
      bindAccountname: '',
      state: '',
      institutionCode: '',
      channelName: '',
      channelList: CHANNELLIST,
      dateResult: '',
      stateList: [
        { stateName: '提现待处理', state: '0' },
        { stateName: '提现处理中', state: '1' },
        { stateName: '提现成功', state: '2' },
        { stateName: '提现失败', state: '3' },
      ],
      stateName: '',
      isvId: '',
      platList: [],
      inputLoading: false,
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    stateRes(state) {
      this.state = state
    },
    channelRes(channel) {
      this.institutionCode = channel
    },
    handleQuery() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const obj = {
        userWithdrawNo: this.userWithdrawNo,
        withdrawNo: this.withdrawNo,
        bindAccountNo: this.bindAccountNo,
        accountNo: this.accountNo,
        bindAccountname: this.bindAccountname,
        state: this.state,
        isvId: this.isvId,
        institutionCode: this.institutionCode,
        beginDate: beginDate,
        endDate: endDate,
      }
      this.$emit('withdraw-event', obj)
    },
    handleClear() {
      this.userWithdrawNo = ''
      this.withdrawNo = ''
      this.bindAccountname = ''
      this.bindAccountname = ''
      this.state = ''
      this.isvId = ''
      this.stateName = ''
      this.institutionCode = ''
      this.channelName = ''
      this.dateResult = ''
      this.bindAccountNo = ''
      this.accountNo = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.trade-header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
