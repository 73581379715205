<template>
  <div class="trade-header">
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">所属平台</span>
          <el-select
            class="right"
            v-model="isvId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="请输入所属平台"
            :remote-method="remoteMethod"
            :loading="inputLoading"
          >
            <el-option
              v-for="item in platList"
              :key="item.isvId"
              :label="item.isvName"
              :value="item.isvId"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">创建时间</span>
          <el-date-picker
            class="right"
            v-model="dateResult"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">支付流水号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="tradeNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">交易状态</span>
          <el-select
            class="right"
            size="small"
            v-model="tradeState"
            placeholder="请选择"
            @change="tradeStateRes"
          >
            <el-option
              v-for="item in tradeStateList"
              :key="item.tradeState"
              :label="item.tradeStateName"
              :value="item.tradeState"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      
      <el-col :span="4">
        <div class="grid-content">
          <span class="left">渠道</span>
          <el-select
            class="right"
            size="small"
            v-model="tradeChannelName"
            placeholder="请选择"
            @change="tradeChannelRes"
          >
            <el-option
              v-for="item in channelList"
              :key="item.channel"
              :label="item.channelName"
              :value="item.channel"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>      
    </el-row>
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">出款方名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="accountName"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">出款方账号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="accountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">到账方名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeCardName"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">到账方账号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeCardNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="btn-content">
          <el-button type="primary" size="medium" @click="handleQuery"
            >查询</el-button
          >
          <el-button size="medium" @click="handleClear">清空</el-button>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { platManageList } from '@/request/api'
import { CHANNELLIST } from '@/assets/js/config'
export default {
  data() {
    return {
      tradeNo: '',
      dateResult: '',
      platList: [],
      isvId: '',
      inputLoading: false,
      tradeType: '',
      accountName:'',
      accountNo:'',
      payeeCardName:'',
      payeeCardNo:'',
      tradeStateName: '',
      tradeState: '',
      tradeChannelName: '',
      tradeChannel: '',
      tradeStateList: [
        { tradeStateName: '已受理', tradeState: '0' },
        { tradeStateName: '成功', tradeState: '1' },
        { tradeStateName: '失败', tradeState: '2' },
        { tradeStateName: '处理中', tradeState: '3' },
        { tradeStateName: '部分成功', tradeState: '4' },
      ],
      channelList: CHANNELLIST,
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    tradeTypeRes(type) {
      this.tradeType = type
    },
    tradeStateRes(state) {
      this.tradeState = state
    },
    tradeChannelRes(channel) {
      this.tradeChannel = channel
    },
    handleQuery() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const obj = {
        accountName: this.accountName,
        accountNo: this.accountNo,
        isvId: this.isvId,
        payeeCardName: this.payeeCardName,
        payeeCardNo: this.payeeCardNo,
        tradeNo: this.tradeNo,
        status: this.tradeState,
        institutionCode: this.tradeChannel,
        beginDate: beginDate,
        endDate: endDate,
      }
      this.$emit('recharge-refund-event', obj)
    },
    handleClear() {
      this.accountName = '';
      this.accountNo = '';
      this.isvId = '';
      this.payeeCardName = '';
      this.payeeCardNo = '';
      this.tradeNo = '';
      this.tradeState = '';
      this.tradeChannelName = '';
      this.dateResult = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.trade-header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
