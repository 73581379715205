<template>
  <div class="trade-header">
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">所属平台</span>
          <el-select
            class="right"
            v-model="isvId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="请输入所属平台"
            :remote-method="remoteMethod"
            :loading="inputLoading"
          >
            <el-option
              v-for="item in platList"
              :key="item.isvId"
              :label="item.isvName"
              :value="item.isvId"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">接入方流水号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="userTradeNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">支付流水号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="tradeNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">渠道</span>
          <el-select
            class="right"
            size="small"
            v-model="tradeChannelName"
            placeholder="请选择"
            @change="tradeChannelRes"
          >
            <el-option
              v-for="item in channelList"
              :key="item.channel"
              :label="item.channelName"
              :value="item.channel"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span class="left">交易类型</span>
          <el-select
            class="right"
            size="small"
            v-model="tradeTypeName"
            placeholder="请选择"
            @change="tradeTypeRes"
          >
            <el-option
              v-for="item in tradeTypeList"
              :key="item.tradeType"
              :label="item.tradeTypeName"
              :value="item.tradeType"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">交易创建时间</span>
          <el-date-picker
            class="right"
            v-model="dateResult"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">付款方名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payerAccountName"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">到账方名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeAccountName"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">交易状态</span>
          <el-select
            class="right"
            size="small"
            v-model="tradeStateName"
            placeholder="请选择"
            @change="tradeStateRes"
          >
            <el-option
              v-for="item in tradeStateList"
              :key="item.tradeState"
              :label="item.tradeStateName"
              :value="item.tradeState"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content">
          <span class="left">付款账号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payerAccountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">到账账号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeAccountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content">
          <span class="left">接入方批次号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="userBatchNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="10">&nbsp;</el-col>
      <el-col :span="4">
        <div class="btn-content">
          <el-button type="primary" size="medium" @click="handleQuery"
            >查询</el-button
          >
          <el-button size="medium" @click="handleClear">清空</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { platManageList } from '@/request/api'
import { CHANNELLIST } from '@/assets/js/config'
export default {
  data() {
    return {
      userTradeNo: '',
      // institutionTradeNo: '',
      platList: [],
      isvId: '',
      inputLoading: false,
      batchNo: '',
      tradeNo: '',
      tradeTypeName: '',
      tradeType: '',
      dateResult: '',
      payerAccountName: '',
      payeeAccountName: '',
      tradeStateName: '',
      tradeState: '',
      tradeChannelName: '',
      tradeChannel: '',
      payerAccountNo: '',
      payeeAccountNo: '',
      userBatchNo: '',
      tradeTypeList: [
        { tradeTypeName: '支付', tradeType: '1' },
        { tradeTypeName: '批量支付', tradeType: '0' },
        { tradeTypeName: '分账', tradeType: '2' },
        { tradeTypeName: '撤销支付', tradeType: '3' },
        { tradeTypeName: '垫付', tradeType: '4' },
        { tradeTypeName: '撤销垫付', tradeType: '5' },
      ],
      tradeStateList: [
        { tradeStateName: '已受理', tradeState: '0' },
        { tradeStateName: '成功', tradeState: '1' },
        { tradeStateName: '失败', tradeState: '2' },
        { tradeStateName: '处理中', tradeState: '3' },
        { tradeStateName: '部分成功', tradeState: '4' },
      ],
      channelList: CHANNELLIST,
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    tradeTypeRes(type) {
      this.tradeType = type
    },
    tradeStateRes(state) {
      this.tradeState = state
    },
    tradeChannelRes(channel) {
      this.tradeChannel = channel
    },
    handleQuery() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const obj = {
        userTradeNo: this.userTradeNo,
        // institutionTradeNo: this.institutionTradeNo,
        batchNo: this.batchNo,
        tradeNo: this.tradeNo,
        type: this.tradeType,
        isvId: this.isvId,
        payerAccountNo: this.payerAccountNo,
        payerAccountName: this.payerAccountName,
        payeeAccountNo: this.payeeAccountNo,
        userBatchNo: this.userBatchNo,
        payeeAccountName: this.payeeAccountName,
        state: this.tradeState,
        institutionCode: this.tradeChannel,
        beginDate: beginDate,
        endDate: endDate,
      }
      this.$emit('trade-event', obj)
    },
    handleClear() {
      this.userTradeNo = ''
      // this.institutionTradeNo = ''
      this.batchNo = ''
      this.isvId = ''
      this.tradeNo = ''
      this.tradeTypeName = ''
      this.tradeType = ''
      this.dateResult = ''
      this.payerAccountName = ''
      this.payeeAccountName = ''
      this.tradeStateName = ''
      this.tradeState = ''
      this.tradeChannelName = ''
      this.tradeChannel = ''
      this.payerAccountNo = ''
      this.payeeAccountNo = ''
      this.userBatchNo = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.trade-header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
