<template>
  <div style="padding-top:10px;">
    <div class="header">
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">时间</span>
            <el-date-picker
              class="right"
              v-model="dateResult"
              type="daterange"
              size="small"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left"><span style="color:red">*</span> 所属平台</span>
            <el-select
              class="right"
              v-model="isvId"
              size="small"
              filterable
              remote
              reserve-keyword
              placeholder="请输入所属平台"
              :remote-method="remoteMethod"
              :loading="inputLoading"
            >
              <el-option
                v-for="item in platList"
                :key="item.isvId"
                :label="item.isvName"
                :value="item.isvId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">支付流水号</span>
            <el-input
              class="right"
              size="small"
              v-model.trim="tradeNo"
              placeholder="请输入支付流水号"
            ></el-input>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="grid-content">
            <span class="left">业务类型</span>
            <el-select
              class="right"
              size="small"
              v-model="category"
              placeholder="请选择"
              @change="selectAccountStateRes"
            >
              <el-option
                v-for="item in accountStateList"
                :key="item.state"
                :label="item.accountStateName"
                :value="item.state"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col :span="6">
          <div class="grid-content">
            <span class="left">支付系统账号</span>
            <el-input
              class="right"
              size="small"
              v-model.trim="accountNo"
              placeholder="请输入支付系统账号"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="btn-content">
            <el-button
              type="primary"
              size="medium"
              style="margin-left:16px;"
              @click="handleQuery"
              >查询</el-button
            >
            <el-button size="medium" @click="handleClear">清空</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <cesTable
      :isButton="true"
      :isSelection="false"
      :isIndex="true"
      :isPagination="true"
      :tableData="tableData"
      :tableCols="tableCols"
      :pagination="pagination"
      :key="keyDate"
      :loading="loading"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    ></cesTable>
  </div>
</template>

<script>
import cesTable from '@/components/table'
import { listStatementDetail, platManageList } from '@/request/api'
import { getDayBefore } from '@/assets/js/utils'
export default {
  components: {
    cesTable,
  },
  data() {
    return {
      platList: [],
      isvId: '',
      inputLoading: false,
      accountNo: '',
      tradeNo: '',
      category: '',
      accountStateList: [
        { accountStateName: '支付', state: 100 },
        { accountStateName: '充值', state: 200 },
        { accountStateName: '提现', state: 300 },
        { accountStateName: '支付退款', state: 400 },
        { accountStateName: '充值退款', state: 500 },
        { accountStateName: '余额同步', state: 600 },
        { accountStateName: '资金归集', state: 700 },
        { accountStateName: '撤销资金归集', state: 800 },
        { accountStateName: '分账', state: 900 },
        { accountStateName: '分成', state: 1000 },
        { accountStateName: '分成退款', state: 1100 },
        { accountStateName: '垫付', state: 1200 },
        { accountStateName: '垫付还款', state: 1300 },
        { accountStateName: '撤销垫付', state: 1400 },
        { accountStateName: '撤销垫付还款', state: 1500 },
      ],

      submitTimeBegin: '',
      submitTimeEnd: '',
      dateResult: [getDayBefore(7), getDayBefore(0)],
      tableData: [],
      keyDate: '',
      tableCols: [
        { label: '账户名称', prop: 'accountName' },
        { label: '支付系统账号', prop: 'accountNo' },
        { label: '支付流水号', prop: 'tradeNo' },
        { label: '渠道', prop: 'institutionName' },
        { label: '账户类型', prop: 'accountSubtypeName' },
        { label: '所属平台', prop: 'isvName' },
        { label: '业务类型', prop: 'categoryName' },
        { label: '流水类型', prop: 'typeName' },
        { label: '交易金额', prop: 'amt' },
        { label: '当前总余额', prop: 'totalAmt' },
        { label: '当前可用余额', prop: 'actualUsableAmt' },
        { label: '当前冻结余额', prop: 'frozenAmt' },
        { label: '交易时间', prop: 'statementTime' },
      ],
      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      loading: false,
    }
  },
  created() {
    // console.log(this.dateResult)
    // this.queryList()
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    async queryList() {
      if (!this.isvId) {
        return this.$message({
          message: '请选择所属平台',
          type: 'error',
          center: 'true',
        })
      }
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const p = {
        page: {
          current: this.pagination.pageNum,
          size: this.pagination.pageSize,
        },
        accountNo: this.accountNo,
        tradeNo: this.tradeNo,
        category: this.category,
        isvId: this.isvId,
        beginDate: beginDate,
        endDate: endDate,
      }

      try {
        this.loading = true
        const res = await listStatementDetail(p)
        this.loading = false
        if (res.status == 200 && res.data) {
          this.tableData = res.data.items
          if (res.data.total && p.page.current === 1) {
            this.pagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
          this.tableData = []
          this.pagination.total = 0
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },

    handleClear() {
      this.tradeNo = ''
      this.isvId = ''
      this.dateResult = [getDayBefore(7), getDayBefore(0)]
      this.accountNo = ''
      this.category = ''
    },

    handleCurrentChange(val) {
      this.pagination.pageNum = val
      this.queryList()
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.queryList()
    },
    selectAccountStateRes(state) {
      this.accountState = state
    },
    bindCardStateRes(state) {
      this.bindCardState = state
    },
    verifyStateRes(state) {
      this.verifyState = state
    },
    channelRes(channel) {
      this.channel = channel
    },
    handleQuery() {
      this.pagination.pageNum = 1
      this.queryList()
    },
  },
}
</script>

<style lang="stylus" scoped>
/deep/.el-tabs__item {
    width: 120px;
    text-align: center;
}

.header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
