<template>
  <div class="trade-header">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">所属平台</span>
          <el-select
            class="right"
            v-model="isvId"
            size="small"
            filterable
            remote
            reserve-keyword
            placeholder="请输入所属平台"
            :remote-method="remoteMethod"
            :loading="inputLoading"
          >
            <el-option
              v-for="item in platList"
              :key="item.isvId"
              :label="item.isvName"
              :value="item.isvId"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">到账方名称</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeCardName"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">到账账号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeAccountNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="left">充值收款编号</span>
          <el-input
            class="right"
            size="small"
            v-model.trim="payeeCardNo"
            placeholder="请输入"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="btn-content">
          <el-button type="primary" size="medium" @click="handleQuery"
            >查询</el-button
          >
          <el-button size="medium" @click="handleClear">清空</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { platManageList } from '@/request/api'
import { CHANNELLIST } from '@/assets/js/config'
export default {
  data() {
    return {
      userTradeNo: '',
      tradeNo: '',
      payeeCardNo: '',
      isvId: '',
      payerCardName: '',
      payerCardNo: '',
      payeeCardName: '',
      payeeAccountNo: '',
      status: '',
      institutionCode: '',

      dateResult: '',

      statusName: '',
      platList: [],
      inputLoading: false,
      statusList: [
        { statusName: '处理中', status: '0' },
        { statusName: '处理失败', status: '1' },
        { statusName: '处理成功', status: '2' },
      ],

      channelName: '',
      channelList: CHANNELLIST,
    }
  },
  methods: {
    async remoteMethod(query) {
      try {
        const p = {
          page: {
            current: 1,
            size: 20,
          },
          isvName: query,
        }
        this.inputLoading = true
        const res = await platManageList(p)
        this.inputLoading = false
        if (res.status === 200 && res.data) {
          this.platList = res.data.items || []
        }
      } catch (error) {
        console.log(error)
      }
    },
    statusRes(status) {
      this.status = status
    },
    channelRes(channel) {
      this.institutionCode = channel
    },
    handleQuery() {
      const date1 = this.dateResult && this.dateResult[0]
      const date2 = this.dateResult && this.dateResult[1]
      const beginDate = date1 ? date1 + ' 00:00:00' : ''
      const endDate = date2 ? date2 + ' 23:59:59' : ''
      const obj = {
        userTradeNo: this.userTradeNo,
        tradeNo: this.tradeNo,
        payeeCardNo: this.payeeCardNo,
        isvId: this.isvId,
        payerCardName: this.payerCardName,
        payerCardNo: this.payerCardNo,
        payeeCardName: this.payeeCardName,
        payeeAccountNo: this.payeeAccountNo,
        status: this.status,
        institutionCode: this.institutionCode,
        beginDate: beginDate,
        endDate: endDate,
      }
      this.$emit('recharge-event', obj)
    },
    handleClear() {
      this.userTradeNo = ''
      this.tradeNo = ''
      this.payeeCardNo = ''
      this.isvId = ''
      this.payerCardName = ''
      this.payerCardNo = ''
      this.payeeCardName = ''
      this.payeeAccountNo = ''
      this.status = ''
      this.institutionCode = ''
      this.dateResult = ''
      this.statusName = ''
      this.channelName = ''
    },
  },
}
</script>

<style lang="stylus" scoped>
.trade-header {
    padding:0 20px 10px 20px;
}

.el-row {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.grid-content {
    display: flex;
    align-items: center;
}

.left {
    margin-right: 20px;
    font-size: 14px;
}

.right {
    flex: 1;
}

.btn-content {
    display: flex;
    justify-content: flex-end;
}
</style>
