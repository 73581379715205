<template>
  <div style="padding-top:10px;">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="支付" name="trade_record">
        <tradeHeader @trade-event="tradeQuery"></tradeHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="tradeTableData"
          :tableCols="tradeTableCols"
          :pagination="tradePagination"
          :loading="tradeLoading"
          :empty-text="emptyText"
          v-if="keyDate === 1"
          @handleDetail="handleDetailTrade"
          @handleCurrentChange="handleTradeCurrentChange"
          @handleSizeChange="handleTradeSizeChange"
        ></cesTable>
      </el-tab-pane>
      <el-tab-pane label="垫付还款" name="trade_repayment">
        <repaymentHeader @trade-event="tradeRepaymentQuery"></repaymentHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="tradeRepaymentTableData"
          :tableCols="tradeRepaymentTableCols"
          :pagination="tradeRepaymentPagination"
          :loading="tradeLoading"
          :empty-text="emptyText"
          v-if="keyDate === 7"
          @handleCurrentChange="handleTradeRepaymentCurrentChange"
          @handleSizeChange="handleTradeRepaymentSizeChange"
        ></cesTable>
      </el-tab-pane>
      <el-tab-pane label="充值在途资金" name="recharge_on">
        <rechargeOnHeader @recharge-event="rechargeOnQuery"></rechargeOnHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="rechargeOnTableData"
          :tableCols="rechargeOnTableCols"
          :pagination="rechargeOnPagination"
          :loading="rechargeOnLoading"
          :empty-text="emptyText"
          v-if="keyDate === 6"
          @handleDetail="handleDetailRecharge"
          @handleCurrentChange="handleRechargeCurrentChange"
          @handleSizeChange="handleRechargeSizeChange"
        ></cesTable>
      </el-tab-pane>
      <el-tab-pane label="充值" name="recharge">
        <rechargeHeader @recharge-event="rechargeQuery"></rechargeHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="rechargeTableData"
          :tableCols="rechargeTableCols"
          :pagination="rechargePagination"
          :loading="rechargeLoading"
          :empty-text="emptyText"
          v-if="keyDate === 2"
          @handleDetail="handleDetail"
          @handleCurrentChange="handleRechargeCurrentChange"
          @handleSizeChange="handleRechargeSizeChange"
        ></cesTable>
      </el-tab-pane>
      <el-tab-pane label="资金归集" name="cashsweep">
        <cashsweepHeader @cashsweep-event="cashsweepQuery"></cashsweepHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="cashsweepTableData"
          :tableCols="cashsweepTableCols"
          :pagination="cashsweepPagination"
          :loading="cashsweepLoading"
          :empty-text="emptyText"
          v-if="keyDate === 4"
          @handleCurrentChange="handleCashsweepCurrentChange"
          @handleSizeChange="handleCashsweepSizeChange"
        ></cesTable>
      </el-tab-pane>
      <el-tab-pane label="提现" name="withdraw">
        <withdrawHeader @withdraw-event="withdrawQuery"></withdrawHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="withdrawTableData"
          :tableCols="withdrawTableCols"
          :pagination="withdrawPagination"
          :loading="withdrawLoading"
          :empty-text="emptyText"
          v-if="keyDate === 3"
          @hadnleOperate="hadnleOperate"
          @handleCurrentChange="handleWithdrawCurrentChange"
          @handleSizeChange="handleWithdrawSizeChange"
        ></cesTable>
      </el-tab-pane>
      <el-tab-pane label="充值退款" name="recharge_refund">
        <rechargeRefundHeader
          @recharge-refund-event="rechargeRefundQuery"
        ></rechargeRefundHeader>
        <cesTable
          :isButton="false"
          :isSelection="false"
          :isIndex="true"
          :showSummary="true"
          :summaryMethod="summaryMethod1"
          :isPagination="true"
          :tableData="rechargeRefundTableData"
          :tableCols="rechargeRefundTableCols"
          :pagination="rechargeRefundPagination"
          :loading="rechargeRefundLoading"
          :empty-text="emptyText"
          v-if="keyDate === 5"
          @handleCurrentChange="handleRechargeRefundCurrentChange"
          @handleSizeChange="handleRechargeRefundSizeChange"
        ></cesTable>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import cesTable from '@/components/table'
import tradeHeader from './components/tradeHeader'
import repaymentHeader from './components/repaymentHeader'
import rechargeRefundHeader from './components/rechargeRefundHeader'
import rechargeHeader from './components/rechargeHeader'
import rechargeOnHeader from './components/rechargeOnHeader'
import cashsweepHeader from './components/cashsweepHeader'
import withdrawHeader from './components/withdrawHeader'
import { floorKeep } from '@/assets/js/utils'
import {
  tradeRecordList,
  rechargeRefundList,
  rechargeList,
  tradeRepaymentList,
  withdrawList,
  cashsweepList,
  getTradeMessageApi,
  getTradeReceiptApi,
  getExpiredMemberApplyApi,
  processInTransitRechargeApi,
} from '@/request/api'
export default {
  components: {
    cesTable,
    tradeHeader,
    repaymentHeader,
    rechargeHeader,
    rechargeOnHeader,
    cashsweepHeader,
    rechargeRefundHeader,
    withdrawHeader,
  },
  data() {
    return {
      activeName: 'trade_record',
      emptyText: '请点击查询按钮查询数据',
      keyDate: 1,
      tradeTableData: [],
      tradeRepaymentTableData: [],
      tradeTableCols: [
        {
          label: '交易报文',
          prop: 'copyMeassage',
          type: 'Detail',
          special: '复制报文',
        },
        { label: '接入方流水号', prop: 'userTradeNo' },
        { label: '支付方流水号', prop: 'tradeNo' },
        { label: '渠道流水号', prop: 'institutionTradeNo' },
        { label: '所属平台', prop: 'isvName' },
        { label: '批次号', prop: 'batchNo' },
        { label: '交易类型', prop: 'typeName' },
        { label: '金额', prop: 'amount' },
        { label: '交易状态', prop: 'stateName' },
        { label: '失败原因', prop: 'returnMsg' },
        { label: '付款方名称', prop: 'payerAccountName' },
        { label: '付款账号', prop: 'payerAccountNo' },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '到账方名称', prop: 'payeeAccountName' },
        { label: '到账账号', prop: 'payeeAccountNo' },
        { label: '原支付流水号', prop: 'relateTradeNo' },
        { label: '交易创建时间', prop: 'created' },
        { label: '交易完成时间', prop: 'finishTime' },
      ],
      tradePagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      tradeRepaymentPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      tradeRepaymentTableCols: [
        { label: '接入方流水号', prop: 'userTradeNo' },
        { label: '支付流水号', prop: 'tradeNo' },
        { label: '垫付流水号', prop: 'paymentTradeNo' },
        { label: '垫付还款流水号', prop: 'relateTradeNo' },
        { label: '所属平台', prop: 'isvName' },
        { label: '交易类型', prop: 'typeName' },
        { label: '金额', prop: 'amount' },
        { label: '交易状态', prop: 'stateName' },
        { label: '渠道', prop: 'institutionName' },
        { label: '付款方名称', prop: 'payerAccountName' },
        { label: '付款账号', prop: 'payerAccountNo' },
        { label: '到账方名称', prop: 'payeeAccountName' },
        { label: '到账账号', prop: 'payeeAccountNo' },
        { label: '交易创建时间', prop: 'createTime' },
        { label: '交易完成时间', prop: 'finishTime' },
      ],
      tradeQueryP: {},
      tradeRepaymentP: {},
      tradeLoading: false,
      rechargeRefundTableData: [],
      rechargeRefundTableCols: [
        { label: '接入流水号', prop: 'userTradeNo' },
        { label: '支付流水号', prop: 'tradeNo' },
        { label: '渠道流水号', prop: 'institutionTradeNo' },
        { label: '金额', prop: 'amount' },
        { label: '交易状态', prop: 'statusName' },
        { label: '失败原因', prop: 'returnMsg' },
        { label: '原充值收款编号', prop: 'rechargeCardNo' },
        { label: '出款方名称', prop: 'accountName' },
        { label: '出款方账号', prop: 'accountNo' },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '到账方名称', prop: 'payeeCardName' },
        { label: '到账方账号', prop: 'payeeCardNo' },
        { label: '所属平台', prop: 'isvName' },
        { label: '创建时间', prop: 'created' },
        { label: '完成时间', prop: 'refundTime' },
      ],
      rechargeRefundPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      rechargeRefundQueryP: {},
      rechargeRefundLoading: false,
      rechargeTableData: [],
      rechargeTableCols: [
        {
          label: '交易报文',
          prop: 'copyMeassage',
          type: 'Detail',
          special: '复制报文',
        },
        { label: '接入方流水号', prop: 'userTradeNo' },
        { label: '支付方流水号', prop: 'tradeNo' },
        { label: '渠道流水号', prop: 'institutionTradeNo' },
        { label: '金额', prop: 'amount' },
        { 
          label: '类型',
          prop: 'typeName',
          itemClass: (row)=> {
            return row.type === 2 && !row.refundAmount ? 'red' : ''
          },
          formatter: (row)=> {
            return `${row.typeName}`
          }
        },
        { label: '交易状态', prop: 'statusName' },
        { label: '失败原因', prop: 'failMsg' },
        { label: '充值收款编号', prop: 'payeeCardNo' },
        { label: '付款方名称', prop: 'payerCardName' },
        { label: '付款账号', prop: 'payerCardNo' },
        // { label: "付款账户", prop: "payerBankOrgId" },
        { label: '到账方名称', prop: 'payeeCardName' },
        { label: '到账账号', prop: 'payeeAccountNo' },
        { label: '所属平台', prop: 'isvName' },
        // { label: "所属平台账号", prop: "openAccount" },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '银行收款时间', prop: 'transferDate' },
        { label: '支付同步时间', prop: 'created' },
      ],
      rechargePagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      rechargeQueryP: {},
      rechargeLoading: false,
      rechargeOnTableData: [],
      rechargeOnTableCols: [
        {
          label: '操作',
          prop: 'copyMeassage',
          type: 'Detail',
          special: '手动入账',
        },
        { label: '支付方流水号', prop: 'tradeNo' },
        { label: '渠道流水号', prop: 'institutionTradeNo' },
        { label: '金额', prop: 'amount' },
        { label: '充值收款编号', prop: 'payeeCardNo' },
        { label: '付款方名称', prop: 'payerCardName' },
        { label: '付款账号', prop: 'payerCardNo' },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '到账方名称', prop: 'payeeCardName' },
        { label: '到账账号', prop: 'payeeAccountNo' },
        { label: '所属平台', prop: 'isvName' },
        { label: '创建时间', prop: 'created' },
        { label: '状态', prop: 'statusName' },
      ],
      rechargeOnPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      rechargeOnQueryP: {},
      rechargeOnLoading: false,
      cashsweepTableData: [],
      cashsweepTableCols: [
        { label: '接入方流水号', prop: 'cashSweepNo' },
        { label: '归集资金', prop: 'amount' },
        { label: '交易类型', prop: 'type' },
        { label: '状态', prop: 'state' },
        { label: '归集发起方名称', prop: 'virtualAccountName' },
        { label: '归集发起方账号', prop: 'virtualAccountNo' },
        { label: '归集平台', prop: 'isvName' },
        { label: '归集平台账户', prop: 'accountNo' },
        { label: '归集创建时间', prop: 'created' },
        { label: '归集完成时间', prop: 'finishTime' },
      ],
      cashsweepPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      cashsweepQueryP: {},
      cashsweepLoading: false,
      withdrawTableData: [],
      withdrawTableCols: [
        {
          label: '操作',
          type: 'Button',
          width: '50',
          op: [
            { text: '复制报文', class: 'handleCopyMessage' },
            { text: '获取回单地址', class: 'handleGetTradeMessage' },
          ],
        },
        { label: '接入方流水号', prop: 'userWithdrawNo' },
        { label: '支付方流水号', prop: 'withdrawNo' },
        { label: '渠道流水号', prop: 'institutionWithdrawNo' },
        { label: '金额', prop: 'amount' },
        { label: '交易状态', prop: 'stateName' },
        { label: '失败原因', prop: 'failReason' },
        { label: '提现方名称', prop: 'bindAccountname' },
        { label: '提现卡号', prop: 'bindAccountNo' },
        { label: '提现银行', prop: 'bindInstitutionCodeName' },
        { label: '渠道', prop: 'institutionCodeName' },
        { label: '交易创建时间', prop: 'created' },
        { label: '交易完成时间', prop: 'finishTime' },
      ],
      withdrawPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      withdrawQueryP: {},
      withdrawLoading: false,
    }
  },
  mounted() {
    // this.queryTradeRecordList();
  },
  methods: {
    summaryMethod1(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        if (column.property === 'amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return floorKeep(prev, curr, 2)
            } else {
              return prev
            }
          }, 0)
          sums[index]
        }
      })
      return sums
    },
    handleClick() {
      switch (this.activeName) {
        case 'trade_record':
          // 交易
          this.keyDate = 1
          this.emptyText = '请点击查询按钮查询数据'
          // this.queryTradeRecordList();
          break
        case 'trade_repayment':
          // 交易
          this.keyDate = 7
          this.emptyText = '请点击查询按钮查询数据'
          // this.queryTradeRecordList();
          break
        case 'recharge':
          // 充值
          this.keyDate = 2
          this.emptyText = '请点击查询按钮查询数据'
          // this.queryRechargeList();
          break
        case 'withdraw':
          // 提现
          this.keyDate = 3
          this.emptyText = '请点击查询按钮查询数据'
          // this.queryWithdrawList();
          break
        case 'cashsweep':
          // 资金归集
          this.keyDate = 4
          this.emptyText = '请点击查询按钮查询数据'
          // this.queryCashsweepList();
          break
        case 'recharge_refund':
          // 充值退款
          this.keyDate = 5
          this.emptyText = '请点击查询按钮查询数据'
          break
        case 'recharge_on':
          // 充值在途资金
          this.keyDate = 6
          this.emptyText = '请点击查询按钮查询数据'
          break
        default:
          break
      }
    },
    async queryTradeRecordList() {
      if( !this.tradeQueryP.isvId ) {
        this.$message.error('请选择平台')
        return
      }
      this.emptyText = ''
      try {
        const p = {
          page: {
            current: this.tradePagination.pageNum,
            size: this.tradePagination.pageSize,
          },
          userTradeNo: this.tradeQueryP.userTradeNo || '',
          institutionTradeNo: this.tradeQueryP.institutionTradeNo || '',
          batchNo: this.tradeQueryP.batchNo || '',
          tradeNo: this.tradeQueryP.tradeNo || '',
          type: this.tradeQueryP.type || '',
          isvId: this.tradeQueryP.isvId || '',
          payerAccountNo: this.tradeQueryP.payerAccountNo || '',
          payerAccountName: this.tradeQueryP.payerAccountName || '',
          payeeAccountNo: this.tradeQueryP.payeeAccountNo || '',
          payeeAccountName: this.tradeQueryP.payeeAccountName || '',
          userBatchNo: this.tradeQueryP.userBatchNo || '',
          state: this.tradeQueryP.state || '',
          institutionCode: this.tradeQueryP.institutionCode || '',
          beginDate: this.tradeQueryP.beginDate || '',
          endDate: this.tradeQueryP.endDate || '',
        }
        this.tradeLoading = true
        const res = await tradeRecordList(p)
        this.tradeLoading = false
        if (res.status === 200 && res.data) {
          this.tradeTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.tradePagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.tradeLoading = false
      }
    },
    async queryTradeRepaymentRecordList() {
      if( !this.tradeRepaymentP.isvId ) {
        this.$message.error('请选择平台')
        return
      }
      this.emptyText = ''
      try {
        let page = {
            current: this.tradeRepaymentPagination.pageNum,
            size: this.tradeRepaymentPagination.pageSize,
          }
        let p = {...this.tradeRepaymentP}
        p.page = page
        this.tradeLoading = true
        const res = await tradeRepaymentList(p)
        this.tradeLoading = false
        if (res.status === 200 && res.data) {
          this.tradeRepaymentTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.tradeRepaymentPagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.tradeLoading = false
      }
    },
    async queryRechargeRefundList() {
      if( !this.rechargeRefundQueryP.isvId ) {
        this.$message.error('请选择平台')
        return
      }
      this.emptyText = ''
      try {
        const p = {
          page: {
            current: this.rechargeRefundPagination.pageNum,
            size: this.rechargeRefundPagination.pageSize,
          },
          ...this.rechargeRefundQueryP,
        }
        this.rechargeRefundLoading = true
        const res = await rechargeRefundList(p)
        this.rechargeRefundLoading = false
        if (res.status === 200 && res.data) {
          this.rechargeRefundTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.rechargeRefundPagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.rechargeRefundLoading = false
      }
    },
    tradeQuery(obj) {
      this.tradeQueryP = obj
      this.tradePagination.pageNum = 1
      this.queryTradeRecordList()
    },
    tradeRepaymentQuery(obj) {
      this.tradeRepaymentP = obj
      this.tradeRepaymentPagination.pageNum = 1
      this.queryTradeRepaymentRecordList()
    },
    rechargeRefundQuery(obj) {
      this.rechargeRefundQueryP = obj
      this.rechargeRefundPagination.pageNum = 1
      this.queryRechargeRefundList()
    },
    async queryRechargeList() {
      if( !this.rechargeQueryP.isvId ) {
        this.$message.error('请选择平台')
        return
      }
      this.emptyText = ''
      try {
        const p = {
          page: {
            current: this.rechargePagination.pageNum,
            size: this.rechargePagination.pageSize,
          },
          userTradeNo: this.rechargeQueryP.userTradeNo || '',
          tradeNo: this.rechargeQueryP.tradeNo || '',
          payeeCardNo: this.rechargeQueryP.payeeCardNo || '',
          isvId: this.rechargeQueryP.isvId || '',
          type: this.rechargeQueryP.type || '',
          payerCardName: this.rechargeQueryP.payerCardName || '',
          payerCardNo: this.rechargeQueryP.payerCardNo || '',
          payeeCardName: this.rechargeQueryP.payeeCardName || '',
          payeeAccountNo: this.rechargeQueryP.payeeAccountNo || '',
          status: this.rechargeQueryP.status || '',
          institutionCode: this.rechargeQueryP.institutionCode || '',
          beginDate: this.rechargeQueryP.beginDate || '',
          endDate: this.rechargeQueryP.endDate || '',
        }
        this.rechargeLoading = true
        const res = await rechargeList(p)
        this.rechargeLoading = false
        if (res.status === 200 && res.data) {
          this.rechargeTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.rechargePagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.rechargeLoading = false
      }
    },
    async queryRechargeOnList() {
      if( !this.rechargeOnQueryP.isvId ) {
        this.$message.error('请选择平台')
        return
      }
      this.emptyText = ''
      try {
        const p = {
          page: {
            current: this.rechargePagination.pageNum,
            size: this.rechargePagination.pageSize,
          },
          payeeCardNo: this.rechargeOnQueryP.payeeCardNo || '',
          isvId: this.rechargeOnQueryP.isvId || '',
          payeeCardName: this.rechargeOnQueryP.payeeCardName || '',
          payeeAccountNo: this.rechargeOnQueryP.payeeAccountNo || '',
          status: 4,
        }
        console.log(p,1111)
        this.rechargeOnLoading = true
        const res = await rechargeList(p)
        this.rechargeOnLoading = false
        if (res.status === 200 && res.data) {
          this.rechargeOnTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.rechargeOnPagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.rechargeOnLoading = false
      }
    },
    rechargeQuery(obj) {
      this.rechargePagination.pageNum = 1
      this.rechargeQueryP = obj
      this.queryRechargeList()
    },
    rechargeOnQuery(obj) {
      this.rechargeOnPagination.pageNum = 1
      this.rechargeOnQueryP = obj
      this.queryRechargeOnList()
    },
    async queryCashsweepList() {
      if( !this.cashsweepQueryP.isvId ) {
        this.$message.error('请选择归集平台')
        return
      }
      this.emptyText = ''
      try {
        const p = {
          page: {
            current: this.cashsweepPagination.pageNum,
            size: this.cashsweepPagination.pageSize,
          },
        }
        const params = { ...p, ...this.cashsweepQueryP }
        this.cashsweepLoading = true
        const res = await cashsweepList(params)
        this.cashsweepLoading = false
        if (res.status === 200 && res.data) {
          this.cashsweepTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.cashsweepPagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.cashsweepLoading = false
        console.log(error)
      }
    },
    cashsweepQuery(obj) {
      this.cashsweepQueryP = { ...obj }
      this.cashsweepPagination.pageNum = 1
      this.queryCashsweepList()
    },
    async queryWithdrawList() {
      if( !this.withdrawQueryP.isvId ) {
        this.$message.error('请选择平台')
        return
      }
      this.emptyText = ''
      try {
        const p = {
          page: {
            current: this.withdrawPagination.pageNum,
            size: this.withdrawPagination.pageSize,
          },
          userWithdrawNo: this.withdrawQueryP.userWithdrawNo || '',
          withdrawNo: this.withdrawQueryP.withdrawNo || '',
          bindAccountNo: this.withdrawQueryP.bindAccountNo || '',
          accountNo: this.withdrawQueryP.accountNo || '',
          bindAccountname: this.withdrawQueryP.bindAccountname || '',
          state: this.withdrawQueryP.state || '',
          isvId: this.withdrawQueryP.isvId || '',
          institutionCode: this.withdrawQueryP.institutionCode || '',
          beginDate: this.withdrawQueryP.beginDate || '',
          endDate: this.withdrawQueryP.endDate || '',
        }
        this.withdrawLoading = true
        const res = await withdrawList(p)
        this.withdrawLoading = false
        if (res.status === 200 && res.data) {
          this.withdrawTableData = res.data.items || []
          if (res.data.total && p.page.current === 1) {
            this.withdrawPagination.total = res.data.total
          }
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.withdrawLoading = false
      }
    },
    hadnleOperate(a, b, c) {
      if (c.class == 'handleCopyMessage') {
        this.handleDetail(b)
      } else {
        this.getTradeMessage(b)
      }
    },
    async getTradeMessage(row) {
      const params = {
        tradeNo: row.withdrawNo,
      }
      try {
        const resText = await getTradeReceiptApi(params)
        if (typeof resText === 'object') {
          this.$message.error(resText.message)
          return
        }
        this.$copyText(resText).then(
          () => {
            this.$message.success('已成功复制，可直接去粘贴')
          },
          () => {
            this.$message.error('复制失败')
          }
        )
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    handleDetailRecharge(row) {
      this.$confirm(
        'd+1到账模式会在充值后次日00:00到账，紧急情况可操作入账，操作后会立刻执行上账，不可撤销，请核对后谨慎操作。',
        '入账确认',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          processInTransitRechargeApi({
            accountNo: row.payeeAccountNo,
            isvId: row.isvId,
            tradeNo: row.tradeNo,
          }).then((res) => {
            if (res.status == 200 && res.data.state === 1) {
              this.$message({
                type: 'success',
                message: '操作成功!',
              })
              this.rechargeOnQuery()
            } else {
              this.$message({
                message: res.message || '服务出错，请重试',
                type: 'error',
                center: 'true',
              })
            }
          })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async handleDetail(row) {
      const params = {
        institutionCode: row.institutionCode,
        tradeNo: row.tradeNo || row.withdrawNo,
        type: this.keyDate,
      }
      try {
        const resText = await getTradeMessageApi(params)
        if (typeof resText === 'object') {
          this.$message.error(resText.message)
          return
        }
        this.$copyText(resText).then(
          () => {
            this.$message.success('已成功复制，可直接去粘贴')
          },
          () => {
            this.$message.error('复制失败')
          }
        )
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    async handleDetailTrade(row) {
      const params = {
        tradeNo: row.tradeNo || row.withdrawNo,
      }
      const params2 = {
        institutionCode: row.institutionCode,
        tradeNo: row.tradeNo || row.withdrawNo,
        type: 1,
      }
      try {
        const resText =
          row.institutionCode === 'ANTB_V2'
            ? await getTradeMessageApi(params2)
            : await getExpiredMemberApplyApi(params)
        if (typeof resText === 'object') {
          this.$message.error(resText.message)
          return
        }
        this.$copyText(resText).then(
          () => {
            this.$message.success('已成功复制，可直接去粘贴')
          },
          () => {
            this.$message.error('复制失败')
          }
        )
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    withdrawQuery(obj) {
      this.withdrawQueryP = obj
      this.withdrawPagination.pageNum = 1
      this.queryWithdrawList()
    },
    handleTradeCurrentChange(val) {
      this.tradePagination.pageNum = val
      this.queryTradeRecordList()
    },
    handleTradeSizeChange(val) {
      this.tradePagination.pageSize = val
      this.queryTradeRecordList()
    },
    handleTradeRepaymentCurrentChange(val) {
      this.tradeRepaymentPagination.pageNum = val
      this.queryTradeRepaymentRecordList()
    },
    handleTradeRepaymentSizeChange(val) {
      this.tradeRepaymentPagination.pageSize = val
      this.queryTradeRepaymentRecordList()
    },
    handleRechargeRefundCurrentChange(val) {
      this.rechargeRefundPagination.pageNum = val
      this.queryRechargeRefundList()
    },
    handleRechargeRefundSizeChange(val) {
      this.rechargeRefundPagination.pageSize = val
      this.queryRechargeRefundList()
    },
    handleRechargeCurrentChange(val) {
      this.rechargePagination.pageNum = val
      this.queryRechargeList()
    },
    handleRechargeSizeChange(val) {
      this.rechargePagination.pageSize = val
      this.queryRechargeList()
    },
    handleCashsweepCurrentChange(val) {
      this.cashsweepPagination.pageNum = val
      this.queryCashsweepList()
    },
    handleCashsweepSizeChange(val) {
      this.cashsweepPagination.pageSize = val
      this.queryCashsweepList()
    },
    handleWithdrawCurrentChange(val) {
      this.withdrawPagination.pageNum = val
      this.queryWithdrawList()
    },
    handleWithdrawSizeChange(val) {
      this.withdrawPagination.pageSize = val
      this.queryWithdrawList()
    },
  },
}
</script>
<style>
  .red{color:red;}
</style>
<style lang="stylus" scoped>

/deep/.el-tabs__item {
    width: 120px;
    text-align: center;
}
</style>
